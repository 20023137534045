import React from "react";
import { graphql } from "gatsby";
import { SliceZone } from "@prismicio/react";
import { Container } from "react-bootstrap";
import Seo from "../components/meta/Seo";
import Layout from "../components/common/Layout";
import components from "../components/slices";

const Post = ({ data }) => {
  if (!data) return null;
  // console.log(data);
  const { page } = data;

  return (
    <>
      <Seo page={page} />
      <Layout>
        <div
          className="container-fluid text-center py-5"
          style={{ backgroundColor: "#F5F5F5" }}
        >
          <h2>{page.data.title}</h2>
        </div>
        <Container className="px-3">
          <SliceZone components={components} slices={page.data.body} />
        </Container>
      </Layout>
    </>
  );
};

export const query = graphql`
  query postQuery($lang: String, $id: String) {
    settings: prismicSettings(lang: { eq: $lang }) {
      ...prismicSettingsFragment
    }
    page: prismicPost(lang: { eq: $lang }, id: { eq: $id }) {
      alternate_languages {
        uid
        type
        lang
      }
      lang
      type
      url
      id
      data {
        meta_title
        meta_description
        meta_image {
          alt
          dimensions {
            height
            width
          }
          url
        }
        body {
          ... on PrismicPostDataBodyPdfViewer {
            id
            slice_label
            slice_type
            primary {
              pdf {
                url
              }
            }
          }
          ... on PrismicPostDataBodyImageWithText {
            id
            primary {
              content {
                richText
              }
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
              title1 {
                text
              }
            }
            slice_label
            slice_type
          }

          ... on PrismicPostDataBodyHeading {
            id
            slice_type
            slice_label
            primary {
              heading {
                text
              }
            }
          }
          ... on PrismicPostDataBodyImageWithTextVertical {
            id
            slice_type
            slice_label
            primary {
              content {
                richText
              }
              heading {
                text
              }
            }
            items {
              image {
                alt
                gatsbyImageData(placeholder: BLURRED)
              }
            }
          }
          ... on PrismicPostDataBodyRichText {
            id
            slice_type
            slice_label
            primary {
              text {
                richText
              }
            }
          }
        }
        image {
          gatsbyImageData(placeholder: BLURRED)
        }
        title
        date
      }
    }
  }
`;

export default Post;
